import ErrorIcon from '@mui/icons-material/Error'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import firebase from 'firebase/compat/app'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import useLogger from 'src/context/LoggerProvider/useLogger'
import { IRegisterUserInput } from 'src/types/graphql'
import ga from 'src/utils/pixels/gtag'
import { Colors, FontSize, FontWeight, Spacing } from '../../constants/theme'
import { RedirectUrls } from '../../types/types'
import BaseButton from '../Buttons/BaseButton'
import FlexBox from '../FlexBox'
import { CODES } from '../Inputs/countries'
import LoginPhoneConfirm from './LoginPhoneConfirm'
import { LoginFooter, LoginHeader, LoginType, LoginTypeEvent } from './common'
import { StyledContainer, StyledRecaptcha } from './styled'

const ERRORS = [
  {
    code: 'auth/invalid-verification-code',
    message: 'Código inválido, por favor intenta de nuevo.'
  },
  {
    code: 'auth/code-expired',
    message: 'El código ha expirado, por favor solicita un nuevo código.'
  },
  {
    code: 'auth/too-many-requests',
    message: 'Demasiados intentos, por favor intenta más tarde.'
  },
  {
    code: 'auth/error-code:-39',
    message: 'Demasiados intentos, por favor intenta más tarde.'
  }
]

const PhoneNumberInput = dynamic(() => import('../Inputs/PhoneNumber'), {
  loading: () => (
    <TextField
      fullWidth
      label="Número telefónico"
      placeholder={`+52 XX XXXX XXXX`}
    />
  )
})
const DEFAULT_PHONE_STATE = { phoneNumber: '', isValidPhone: false }

function loadRecaptchaScript(languageCode: 'es') {
  if (document.head.getElementsByClassName('recaptcha')) {
    return
  }

  const script = document.createElement('script')
  script.src = `https://www.google.com/recaptcha/api.js?hl=${languageCode}`
  script.async = true
  script.defer = true
  script.className = 'recaptcha'
  document.head.appendChild(script)
}

type IProps = {
  isModal: boolean
  loginType: LoginType
  setLoginType: (type: LoginType) => void
  saveUserInfo: (
    variables: IRegisterUserInput,
    type: LoginTypeEvent
  ) => Promise<void>
}

export default function LoginPhone({
  isModal,
  loginType,
  setLoginType,
  saveUserInfo
}: IProps) {
  const [country, setCountry] = useState<string>('México')
  const [{ phoneNumber, isValidPhone }, setPhoneNumber] =
    useState(DEFAULT_PHONE_STATE)
  const [confirmationResult, setConfirmationResult] =
    useState<firebase.auth.ConfirmationResult>()
  const [appVerifier, setAppVerifier] =
    useState<firebase.auth.RecaptchaVerifier>()
  const [recaptchaSolved, setSolved] = useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean>(false)
  const [codeStep, setCodeStep] = useState<boolean>(false)

  const { enqueueSnackbar } = useSnackbar()
  const logger = useLogger()

  const cleanup = useCallback(
    (error?: Error) => {
      if (error) {
        enqueueSnackbar('¡Hubo un error! Por favor intenta de nuevo.', {
          variant: 'error'
        })
        logger.error('Error phone login', error)
      }

      setCodeStep(false)
      setConfirmationResult(undefined)
      setAppVerifier(undefined)
      setSolved(false)

      try {
        appVerifier?.clear()
      } catch (error) {
        /** */
      }
    },
    [appVerifier, enqueueSnackbar, logger]
  )

  useEffect(() => {
    if (isValidPhone && !appVerifier && firebase.app()) {
      loadRecaptchaScript('es')
      try {
        const newAppVerifier = new firebase.auth.RecaptchaVerifier(
          'recaptcha-container',
          { callback: () => setSolved(true) },
          firebase.app()
        )
        setAppVerifier(newAppVerifier)
        newAppVerifier.render()
      } catch (error) {
        cleanup(error as Error)
      }
    }
  }, [isValidPhone, appVerifier, cleanup])

  if (codeStep && confirmationResult) {
    const handleOnConfirmCode = (code: string) => {
      if (!phoneNumber || !recaptchaSolved) return
      confirmationResult
        .confirm(code)
        .then(async ({ additionalUserInfo }) => {
          if (additionalUserInfo?.isNewUser) {
            await saveUserInfo({ phoneNumber }, 'phone')
          } else {
            ga.event('login', {
              method: 'phone',
              page: window.location.pathname
            })
          }
        })
        .catch((error) => {
          const errorFound = ERRORS.find((e) => e.code === error.code)
          if (errorFound) {
            enqueueSnackbar(errorFound.message, { variant: 'error' })
            return cleanup()
          }
          cleanup(error)
        })
    }

    return (
      <LoginPhoneConfirm
        phoneNumber={phoneNumber}
        handleOnConfirmCode={handleOnConfirmCode}
        returnPhone={() => cleanup()}
      />
    )
  }

  const handleOnLoginPhoneNumber = () => {
    if (!isValidPhone) return setHasError(true)
    if (!appVerifier || !recaptchaSolved) return
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        setConfirmationResult(confirmationResult)
        setCodeStep(true)
      })
      .catch((error) => cleanup(error))
  }

  return (
    <StyledContainer>
      <LoginHeader isModal={isModal} />

      <FlexBox fullWidth direction="column" pt={Spacing.medium} gap={1.5}>
        <FormControl fullWidth>
          <InputLabel id="phoneNumberCode-label">País/Región</InputLabel>
          <Select
            fullWidth
            id="phoneNumberCode"
            name="phoneNumberCode"
            labelId="phoneNumberCode-label"
            label="País/Región"
            type="phone"
            MenuProps={{ PaperProps: { sx: { maxHeight: '30%' } } }}
            value={country}
            onChange={({ target: { value } }) => setCountry(value)}
          >
            {CODES.map(({ code, name }) => (
              <MenuItem key={name} value={name}>
                {name} ({code})
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <PhoneNumberInput
          country={country}
          initialPhoneNumber={phoneNumber}
          setPhoneState={(val) => setPhoneNumber(val)}
          hasError={hasError}
          setHasError={setHasError}
        />
      </FlexBox>

      <FlexBox fullWidth pt={0.5}>
        {hasError && (
          <Typography fontSize={FontSize.smallest} color="error">
            <ErrorIcon sx={{ mb: -0.5 }} fontSize="small" /> El número de
            teléfono es obligatorio.
          </Typography>
        )}

        {!hasError && (
          <Typography fontSize={FontSize.smallest} color={Colors.black400}>
            Te vamos a confirmar el número por mensaje de texto.{' '}
            <Link href={RedirectUrls.TermsAndConditions} passHref>
              <Typography
                component="span"
                fontSize={FontSize.smallest}
                style={{ textDecoration: 'underline' }}
                fontWeight={FontWeight.medium}
              >
                Políticas de privacidad.
              </Typography>
            </Link>
          </Typography>
        )}
      </FlexBox>

      <FlexBox
        fullWidth
        center
        sx={{ display: isValidPhone ? 'block' : 'none' }}
        mt={1}
      >
        <StyledRecaptcha id="recaptcha-container" />
      </FlexBox>

      <FlexBox fullWidth direction="column" center>
        <BaseButton
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          rounded
          onClick={handleOnLoginPhoneNumber}
          sx={{ mt: 1.5 }}
        >
          Continuar
        </BaseButton>
      </FlexBox>

      <LoginFooter
        loginType={loginType}
        setLoginType={setLoginType}
        saveUserInfo={saveUserInfo}
      />
    </StyledContainer>
  )
}
